<template>
  <div id="user-edit">
    <vs-alert color="danger" :title="$t('administration.useredit.user_not_found.title')" :active.sync="isUserNotFound">
      <span>{{ $t('administration.useredit.user_not_found.message', { 'userId': $route.params.userId }) }}</span>
      <span v-if="$acl.check('admin')">
        <span>{{ $t('administration.useredit.user_not_found.verify') }}</span>
        <router-link :to="{name:'user-list'}" class="text-inherit underline">
          {{ $t('administration.useredit.user_not_found.all_users') }}
        </router-link>
      </span>
    </vs-alert>
    <vx-card v-if="user" class="pt-5 pb-5">
      <upload-avatar :user="user" @update:avatar="avatarUpdate" />
      <user-form :user="user" @update:user="userUpdate" />
    </vx-card>
  </div>
</template>

<script>
import UploadAvatar from '@/views/apps/user/user-form/UploadAvatar'
import UserForm from '@/views/apps/user/user-form/UserForm'

// Store Module
import moduleUserManagementMixin from '@/store/user-management/moduleUserManagementMixin'

export default {
  components: {
    UploadAvatar,
    UserForm
  },
  mixins: [moduleUserManagementMixin],
  data () {
    return {
      user: null,
      isUserNotFound: false,
      fileAvatar: null
    }
  },
  methods: {
    fetchUser (userId) {
      this.$store.dispatch('userManagement/fetchUser', userId)
        .then(res => { this.user = res.data })
        .catch(err => {
          if (err.response.status === 404) {
            this.isUserNotFound = true
            return
          }
          console.error(err) // eslint-disable-line no-console
        })
    },
    async avatarUpdate (file)  {
      this.fileAvatar = file
      if (file) {
        const reader = new FileReader()
        reader.onload = e => {
          this.$set(this.user, 'tempAvatar', e.target.result)
          this.$set(this.user, 'fileAvatar', this.fileAvatar)
        }
        reader.readAsDataURL(file)
      } else {
        this.$set(this.user, 'tempAvatar', null)
        this.$set(this.user, 'fileAvatar', null)
      }
    },
    async userUpdate (user) {
      if (this.fileAvatar) {
        const response = await this.$store.dispatch('mediaObject/addMediaObjectAvatar', {
          file: this.user.fileAvatar
        })
        this.user.avatar = response.data
      }
      user.avatar = this.user.avatar
      await this.$store.dispatch('userManagement/updateUser', user)
        .then(() => {
          this.$router.push({'name': 'user-list'})
          this.$vs.notify({
            color: 'success',
            title: this.$t('administration.userform.form.submit.success.notify.title'),
            text: this.$t('administration.userform.form.submit.success.notify.text', { username: user.username })
          })
        })
        .catch((error) => {
          console.error(error) // eslint-disable-line no-console
          this.$vs.notify({
            color: 'danger',
            title: this.$t('administration.userform.form.submit.error.notify.title'),
            text: this.$t('administration.userform.form.submit.error.notify.text', { error: error.response.data['hydra:description'] }),
            fixed: true
          })
        })
    }
  },
  created () {
    this.fetchUser(this.$route.params.userId)
  }
}
</script>

<style scoped>

</style>
